import React from 'react'
import { graphql } from 'gatsby'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import Wolflayout from '../components/wolflayout'
import Banner from '../components/NewTheme/Ui/Banner'
import FilteredActivities from '../components/NewTheme/Programmation/FilteredActivities'

const BienEtre = ({data}) => {
  const metaTitle = 'Bien-être | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, baie-saint-paul, bien-être'

  const bienEtreEvent = data.allPrismicEvents.edges.filter(({ node }) => {
      // return node.data.is_show === true && node.data.isbienetre === true
      return node.data.isbienetre === true
    })

  return (
    <Wolflayout mobileTitle={'Bien-être'}>
        <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
        <Banner title="Bien-être" />
        <div className="container content pb-12">

            {/* TOP TEXT */}
            <div className='text-center'>
                <h4 className='!font-obviously !font-regular !text-xl'>Envie de commencer ta journée de festival du bon pied? On a ce qu'il te faut. Que tu sois team méditation musicale, team club de course ou team yoga, on te propose des activités qui te permettront de trouver ton équilibre au cœur du chaos, de reconnecter avec la nature et de repartir avec un petit boost de dopamine. Tes lendemains de veille n’auront jamais eu si fière allure!</h4>
            </div>
        
            {/* PROG */}
            <div>
                <FilteredActivities eventList={bienEtreEvent} />
            </div>
        </div>
    </Wolflayout>
  )
}

export const query = graphql`
    query {
      allPrismicEvents {
        edges {
          node {
            uid
            slug
            data {
              main_image {
                alt
                copyright
                gatsbyImageData
                url
              }
              title {
                text
              }
              start_time
              end_time
              description {
                text
              }
              lpdv_id {
                text
              }
              is_show
              free
              isbienetre
              isentrevue
              isconference
              isplaisirdivers
              isfamille
              hidefromprog
              stage {
                document {
                  ... on PrismicStages {
                    uid
                    slug
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `

export default BienEtre